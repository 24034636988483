import DynamicPage from '@xpedeo/core/dist/components/DynamicPage'

export default DynamicPage.extend({
  computed: {
    dynamicPage () {
      if (this.pageData.Class === 'XpSearchPage' || this.pageData.Class === 'XpSearch') {
        return 'XpSearchPage'
      }
      return this.$xp.http.getPageClass(this.pageData.Class)
    }
  }
})
